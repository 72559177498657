import React from "react"
import {Link,graphql} from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

 
const BlogTemplateHtml = ({pageContext,data}) => {  
    const {prev,next} = pageContext
    const post = data.markdownRemark
    
    return (
      <div>
        <Layout>
            <Seo />
            <div className="container-fluid single">
              <div className="row">
                <ul className="breadcrumb">
                  <li><Link to={`/`}>TOP</Link></li>
                  <li><Link to={`/blog/`}>BLOG</Link></li>
                  <li className="bc-blogtitle">{post.frontmatter.title}</li>
                </ul>{/*.breadcrumb*/}
                <div className="contents">
                  <div className="cat-title">BLOG</div>
                  <article>
                    <div className="entry-date">{post.frontmatter.date}</div>
                    <div className="entry-title">{post.frontmatter.title}</div>
                    <img src={post.frontmatter.thumbnail} alt={post.frontmatter.title} className="blog-thumbnail" />
                    <div dangerouslySetInnerHTML={{ __html: post.html }} className="entry-content" />
                  </article>
                  <div className="nav-links">
                    {prev && (
                        <Link to={`/blog${prev.fields.slug}`} className="prev">{/*`*/}
                            <div className="button-left bg-img" />
                        </Link>
                    )}
                    <Link to={`/blog/`}>CLOSE</Link>
                    {next && (
                        <Link to={`/blog${next.fields.slug}`} className="next">{/*`*/}
                            <div className="button-right bg-img" />
                        </Link>
                    )}
                  </div>{/*.nav-links*/}
                </div>{/*.contents*/}
              </div>{/*.row*/}
            </div>{/*.container-fluid*/}
          </Layout>
        </div>
      )
}

export default BlogTemplateHtml
    
export const data = graphql`
    query($slug: String) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
           fields {
                slug
            }
            html
            frontmatter {
                title
                date
                thumbnail
            }
        }
    }
`